// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { Card, FormControl, InputGroup, Modal, Table } from 'react-bootstrap';
import { Button } from '@seeqdev/qomponents';
import { useTranslation } from 'react-i18next';
import { CopyableInputField } from '@/core/CopyableInputField.molecule';
import { sqAccessKeysApi } from '@/sdk';
import { formatTime as formatDateTime } from '@/datetime/dateTime.utilities';
import _ from 'lodash';
import { onEnterKeypress } from '@/core/onEnterKeypress.util';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { successToast } from '@/utilities/toast.utilities';
import { sqWorksheetStore } from '@/core/core.stores';

export interface AccessKeyProps {
  onClose: () => void;
  show: boolean;
}

export const AccessKeyModal: React.FunctionComponent<AccessKeyProps> = (props) => {
  const { t } = useTranslation();
  const { onClose } = props;
  const [newCommonName, setNewCommonName] = useState('');
  const [keyPassword, setKeyPassword] = useState('');
  const [keyName, setKeyName] = useState('');
  const [showCreatedKey, setShowCreatedKey] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [keyDeletionCandidate, setKeyDeletionCandidate] = useState({
    name: '',
    description: '',
  });
  const [keys, setKeys] = useState([]);
  useEffect(() => {
    refreshKeys();
  }, []);

  /**
   * Closes the modal.
   */
  const closeModal = () => {
    onClose();
  };

  /**
   * Fetches all the Access Keys from Seeq and refreshes the list in the modal.
   */
  const refreshKeys = () => {
    sqAccessKeysApi
      .getKeys({ offset: 0, limit: 1000, getAll: false })
      .then(({ data: keylist }) => setKeys(keylist.keys));
  };

  /**
   * Dispatches a request to create a new Access Key with the given common name.
   *
   * @param {string} label - the common name to be associated with the new token.
   */
  const createKey = (label: string) => {
    setNewCommonName('');
    sqAccessKeysApi.createKey({ name: label }).then(({ data: key }) => {
      setKeyPassword(key.password);
      setKeyName(key.name);
      setShowCreatedKey(true);
      refreshKeys();
    });
  };

  /**
   * Deletes the current keyDeletionCandidate and notifies the user.
   */
  const deleteKey = () => {
    return sqAccessKeysApi
      .deleteKey({ keyName: keyDeletionCandidate.name })
      .then(refreshKeys)
      .then(() => {
        setShowDeleteWarning(false);
        setKeyDeletionCandidate({ name: '', description: '' });
        successToast({ messageKey: 'ACCESS_KEY.DELETE_SUCCESS' });
      });
  };

  /**
   * Shows the delete warning for the given token and gives the user the option to confirm deletion.
   *
   * @param {AccessKeyOutputV1} token - The token to warn about deleting.
   */
  const deleteWarning = (token) => {
    setShowDeleteWarning(true);
    setKeyDeletionCandidate(token);
  };

  /**
   * Formats timestamps for the token modal.
   *
   * @param dateTime - the timestamp to format.
   * @param {boolean} summary - generates a short date if true, or a long date if false.
   */
  const formatTime = (dateTime, summary) => {
    const format = summary ? 'MMM D, YYYY' : 'MMM D, YYYY h:mm A';
    if (dateTime) {
      return formatDateTime(dateTime, sqWorksheetStore.timezone, format);
    } else {
      return '';
    }
  };

  /**
   * Assembles the table containing access key information to be displayed to the user.
   */
  const buildKeyList = () =>
    _.map(keys, (item) => {
      return (
        <tr data-testid="key-row" key={item.name}>
          <td className="forceVerticalAlignMiddle nowrap">
            <HoverTooltip text="DELETE">
              <Button
                testId="delete-button"
                variant="danger"
                icon="fa-trash-can"
                iconStyle="white"
                onClick={() => deleteWarning(item)}
                extraClassNames="muted"
              />
            </HoverTooltip>
          </td>
          <td className="col-sm-4 forceVerticalAlignMiddle">{item.description}</td>
          <td className="nowrap forceVerticalAlignMiddle">
            <HoverTooltip text={formatTime(item.createdAt, false)}>
              <span>{formatTime(item.createdAt, true)}</span>
            </HoverTooltip>
          </td>
          <td className="nowrap forceVerticalAlignMiddle">
            <HoverTooltip text={formatTime(item.lastUsed, false)}>
              <span>{formatTime(item.lastUsed, true)}</span>
            </HoverTooltip>
          </td>
          <td className="forceVerticalAlignMiddle">
            <CopyableInputField
              value={item.name}
              notifyMessage="ACCESS_KEY.COPY_SUCCESS_NAME"
              fieldTooltip="ACCESS_KEY.KEY_NAME"
              readOnly={true}
              buttonTooltip="ACCESS_KEY.COPY_TO_CLIPBOARD"
              testId={`${item.name}row`}
            />
          </td>
        </tr>
      );
    });

  return (
    <Modal show={true} onShow={refreshKeys} onHide={closeModal} animation={false} data-testid="accessKeyModal">
      <Modal.Header closeButton={true}>
        <Modal.Title>{t('ACCESS_KEY.MODAL_TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb10">
          <InputGroup>
            <FormControl
              data-testid="create-key-field"
              autoComplete="off"
              type="text"
              name="commonName"
              className="width-maximum"
              value={newCommonName}
              onChange={(e) => setNewCommonName(e.target.value)}
              placeholder={t('ACCESS_KEY.NAME')}
              required={true}
              onKeyUp={onEnterKeypress(createKey)}
            />
            <InputGroup.Append className="createInputGroup">
              <InputGroup.Text data-testid="create-key-button">
                <Button
                  variant="theme"
                  label={t('ACCESS_KEY.CREATE_KEY')}
                  disabled={!newCommonName}
                  onClick={() => createKey(newCommonName)}
                />
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </div>

        <Card data-testid="created-key-info" hidden={!showCreatedKey} className="sqGrayBox mtb10">
          <Card.Body>
            <p>{t('ACCESS_KEY.NEW_KEY_DETAILS')}</p>
            <div className="form-group row">
              <label className=" col-sm-2 col-form-label">{t('ACCESS_KEY.KEY_NAME')}</label>
              <div className="col-sm-8">
                <CopyableInputField
                  testId="new-key-name"
                  readOnly={true}
                  fieldTooltip="ACCESS_KEY.KEY_NAME"
                  buttonTooltip="ACCESS_KEY.COPY_TO_CLIPBOARD"
                  notifyMessage="ACCESS_KEY.COPY_SUCCESS_NAME"
                  value={keyName}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">{t('ACCESS_KEY.KEY_PASSWORD')}</label>
              <div className="col-sm-8">
                <CopyableInputField
                  testId="new-key-password"
                  readOnly={true}
                  fieldTooltip="ACCESS_KEY.KEY_PASSWORD"
                  value={keyPassword}
                  buttonTooltip="ACCESS_KEY.COPY_TO_CLIPBOARD"
                  notifyMessage="ACCESS_KEY.COPY_SUCCESS_PASSWORD"
                />
              </div>
            </div>
          </Card.Body>
        </Card>
        <div data-testid="delete-banner" hidden={!showDeleteWarning} className="alert alert-danger">
          <p>{t('ACCESS_KEY.DELETE_WARNING')}</p>
          <br />
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">{t('ACCESS_KEY.NAME')}</label>
            <div className="col-sm-8">
              <FormControl
                data-testid="delete-key-common-name"
                type="text"
                className="form-control"
                readOnly={true}
                value={keyDeletionCandidate.description}
              />
            </div>
          </div>
          <div className=" form-group row">
            <label className=" col-sm-2 col-form-label">{t('ACCESS_KEY.KEY_NAME')}</label>
            <div className=" col-sm-8">
              <FormControl
                data-testid="delete-key-name"
                type=" text"
                className=" form-control"
                readOnly={true}
                value={keyDeletionCandidate.name}
              />
            </div>
          </div>
          <br />
          <Button
            variant="danger"
            onClick={deleteKey}
            label={t('DELETE')}
            extraClassNames="mr5"
            testId="confirmDeleteButton"
          />
          <Button
            variant="theme"
            onClick={() => setShowDeleteWarning(false)}
            label={t('CANCEL')}
            testId="cancelDeleteButton"
          />
        </div>
        <div className=" tableWrapper mt15 mb0 small" hidden={keys.length === 0}>
          <Table data-testid="key-table" striped={true} size=" sm">
            <thead>
              <tr>
                <th />
                <th>{t('ACCESS_KEY.NAME')}</th>
                <th>{t('ACCESS_KEY.CREATED_AT')}</th>
                <th className=" text-nowrap">{t('ACCESS_KEY.LAST_USED')}</th>
                <th className=" text-nowrap">{t('ACCESS_KEY.KEY_NAME')}</th>
              </tr>
            </thead>
            <tbody>{buildKeyList()}</tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal} label={t('CLOSE')} testId="close-button" />
      </Modal.Footer>
    </Modal>
  );
};
